<template>
  <div class="userDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.user.id ? '编辑' : '新增' }}角色</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="ID" v-if="data.user.id">
            <el-input v-model="data.user.id" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="特殊身份" v-if="data.user.admin">
            <el-tag type="primary">超管</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="角色">
            <el-select v-model="data.user.roleId" style="width: 100%;">
              <el-option v-for="item in show.roles" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="启用状态" v-if="data.user.admin">
            <el-tag type="success" v-if="data.user.hasUsing">启用</el-tag>
            <el-tag type="danger" v-if="!data.user.hasUsing">禁用</el-tag>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="姓名、昵称">
        <el-input v-model="data.user.nick" />
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="data.user.phone" />
      </el-form-item>
      <el-form-item label="创建时间" v-if="data.user.createDatetime">
        <el-input v-model="data.user.createDatetime" disabled />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'

const router = useRouter()
const show = reactive({
  roles: []
})
const pageParam = cache.getObject(cache.keys.pageParam + "userDetail")
const data = reactive({
  user: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {}
})

api.get('/backend/role/getAllRole').then(res => {
  show.roles = res.roleList
})

const save = () => {
  if (!data.user.id) {
    api.post('/backend/user/add', {
      nick: data.user.nick,
      phone: data.user.phone,
      roleId: data.user.roleId
    }).then(() => {
      ElMessage.success('保存成功')
      router.go(-1)
    })
  } else {
    api.post('/backend/user/setById', {
      id: data.user.id,
      nick: data.user.nick,
      phone: data.user.phone,
      roleId: data.user.roleId
    }).then(() => {
      ElMessage.success('保存成功')
      router.go(-1)
    })
  }
}
</script>

<style lang="less">
.userDetail {
  margin: auto;
  max-width: 900px;
}
</style>